<template>
  <h1>Shopping cart page</h1>
  <div v-for="product in shoppingCart" :key="product.id">
      <h3>{{ product.title }}</h3>
      <p>{{ product.price }}</p>
  </div>
</template>

<script>
export default {
    name: "ShoppingCartPage",
    props: ['shoppingCart'],
}
</script>

<style scoped>

</style>
