<template>
    <h3 v-if="!productFound">Product not found</h3>
    <div v-if="productFound"><h1>ProductDetail page</h1>
        <h2>{{ product.title }}</h2>
        <h3>{{ product.price }}</h3>
        <p>{{ product.description }}</p>
        <button @click="$emit('addToCart', product.id)">Add to cart</button>
    </div>
</template>

<script>

export default {
    name: "ProductDetailPage",
    props: ['products', 'shoppingCart'],
    emits: ['addToCart'],
    computed: {
        product() {
            let id = parseInt(this.$route.params.id);

            return this.products.find(prod => prod.id === id)
        },
        productFound() {
            return !!this.product
        }
    }
}
</script>

<style scoped>

</style>
