<template>
    <h2>Available items</h2>
    <div v-for="product in products" :key="product.id" :product="product">
        <router-link :to="`/product/${product.id}`">
            <h3>{{ product.title }}</h3>
        </router-link>
        <p>{{ product.price }}</p>
        <button @click="$emit('addToCart',product.id)">Add to cart</button>
    </div>
</template>

<script>

export default {
    name: "ProductsListPage",
    props: ['products', 'shoppingCart'],
    emits: ['addToCart'],
}
</script>

<style scoped>

</style>
