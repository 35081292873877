<template>
    <h1>Customer details</h1>
    <div>
        <input placeholder="Name" v-model="name"/>
        <input placeholder="Age" type="number" v-model.number="age"/>
        <input placeholder="Address" v-model="address"/>
        <button @click="displayData">Save</button>
    </div>
</template>

<script>
export default {
    name: "UserInfoPage",
    data() {
        return {
            'name': '',
            'age': 0,
            'address': '',
        }
    },
    methods: {
        displayData(){
            alert(JSON.stringify(this.$data))
        },
    }
}
</script>

<style scoped>

</style>
