import axios from "axios";

export default {
    data() {
        return {
            shoppingCartIds: [],
            products: null,
        }
    },
    created() {
        axios.get('https://dummyjson.com/products').then(response => {
            this.products = response.data.products
        })
    },
}
