<template>
    <router-link to="/cart" >Go to cart</router-link>
    <router-view
        :products="products"
        :shoppingCart="shoppingCart"
        @addToCart="addToCart"
    ></router-view>
</template>

<script>
import ProductsLoaderMixin from "@/mixins/ProductsLoaderMixin";
// import products from "@/pages/products";
export default {
    name: 'App',
    mixins: [ProductsLoaderMixin],
    data() {
        return {
            shoppingCartIds: [],
        }
    },
    computed: {
        shoppingCart () {
            return this.shoppingCartIds.map(id => {
                return this.products.find(prod => prod.id === id);
            });
        },
    },
    methods: {
        addToCart(id) {
            this.shoppingCartIds.push(id)
            console.log(this.shoppingCartIds)
        }
    }
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}
</style>
